import React from "react";
import Link from "next/link";

export default function Login() {
  return (
    <div className="flex justify-end gap-3">
      <Link href="/auth/login">
        <button className="h-8 w-20 items-center rounded-xl border-2 border-white bg-transparent font-roboto text-sm font-bold text-white hover:opacity-80 md:h-10 md:w-36 md:text-base">
          Login
        </button>
      </Link>
      <Link href="/auth/register">
        <button className="h-8 w-20 items-center rounded-xl bg-white font-roboto text-sm font-bold text-[#142D59] hover:opacity-80 md:h-10 md:w-36 md:text-base">
          Daftar
        </button>
      </Link>
    </div>
  );
}
