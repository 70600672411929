import Link from "next/link";

export default function Whatsapp() {
  return (
    <>
      <div className="fixed bottom-4 right-4 z-20 space-y-3 lg:bottom-4 lg:right-4">
        <div
          className={`flex flex-col items-center rounded-full bg-white p-2 shadow-md `}
        >
          <Link href="/tentang-kami/kontak">
            <a
              href="/tentang-kami/kontak"
              className="hover:opacity-80 focus:outline-none"
            >
              <img
                src="/images/to-email.svg"
                alt="contact"
                className="h-6 w-6 lg:h-10 lg:w-10 "
              />
            </a>
          </Link>
        </div>
        <div
          className={`flex flex-col items-center rounded-full bg-green-500 p-2 shadow-md `}
        >
          <Link href="https://web.whatsapp.com/send/?phone=6285819336010&text&app_absent=0">
            <a
              href="https://web.whatsapp.com/send/?phone=6285819336010&text&app_absent=0"
              target="_blank"
              className="hover:opacity-80 focus:outline-none"
            >
              <img
                src="/images/whatsapp.svg"
                alt="whatsapp"
                className="h-6 w-6 lg:h-10 lg:w-10 "
              />
            </a>
          </Link>
        </div>
      </div>
    </>
  );
}
