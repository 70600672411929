import { useState, useEffect } from "react";
import { gql } from "urql";
import { useQuery } from "urql";
import { client } from "../../graphql/client";

const query = gql`
  query MyQuery {
    programs(
      where: {
        _or: [{ is_sma: { _eq: false } }, { is_sma: { _is_null: true } }]
      }
    ) {
      id
      name
      image
      description
    }
  }
`;

export async function getPrograms() {
  const { data } = await client.query(query).toPromise();

  const arr = data?.programs.map((item) => {
    return {
      id: item.id,
      name: item.name,
      image: item.image,
      description: item.description,
      href: `/program/${encodeURI(item.name)}`,
    };
  });

  return arr;
}

export function useDataProgram() {
  const [program, setProgram] = useState([]);
  const [{ data: tempData, fetching, error }] = useQuery({
    query: query,
  });

  useEffect(() => {
    if (tempData) {
      const arr = [];
      for (const item of tempData.programs) {
        const body = {
          id: item.id,
          name: item.name,
          image: item.image,
          description: item.description,
          href: `/program/${encodeURI(item.name)}`,
        };
        arr.push(body);
      }
      setProgram(arr);
    }
  }, [tempData]);

  return { program, fetching, error };
}
