import toast, { Toaster as Toast, ToastBar } from "react-hot-toast";

export default function Toaster() {
  return (
    <Toast
      position="bottom-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        style: {
          minWidth: "300px",
        },
        success: {
          style: {
            background: "#E6F4E6",
            color: "#008F00",
            border: "2px solid #008F00",
          },
        },
        error: {
          style: {
            background: "#FCE8E8",
            color: "#E01717",
            border: "2px solid #E01717",
            transition: "height 1s",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon }) => (
            <div className=" flex w-full justify-between px-2">
              <div className="flex">
                {icon}
                <div className="mx-4 flex flex-col text-left">
                  <p className={` font-bold`}>{t.type}</p>
                  <p className="text-[#1C1C1C]">{t.message + ""}</p>
                </div>
              </div>
              <button
                className="text-[#1C1C1C]"
                onClick={() => toast.dismiss(t.id)}
              >
                <img src={"/icon/close.svg"} className="fill-red-400" />
              </button>
            </div>
          )}
        </ToastBar>
      )}
    </Toast>
  );
}
