import React from "react";
import Link from "next/link";

export default function ActiveLink({ text, href, active, getHover, children }) {
  const link = href.split("/")[1];

  const handleHover = React.useCallback((text, bool) => {
    getHover(text, bool);
  }, []);

  return (
    <div
      onMouseEnter={(e) => {
        handleHover(e.target.innerText, true);
      }}
      onClick={(e) => {
        handleHover(e.target.innerText, true);
      }}
      onMouseLeave={(e) => {
        handleHover(e.target.innerText, false);
      }}
      className="relative z-10 flex flex-col items-center hover:text-opacity-80"
    >
      <Link href={href}>
        <a
          className={`cursor-pointer whitespace-nowrap p-2 text-white lg:px-2 xl:px-4 ${
            active === href
              ? " font-extrabold"
              : active.includes(link) && link !== ""
              ? " font-extrabold"
              : "font-medium opacity-80"
          }  relative font-nunito text-sm`}
        >
          {text}
        </a>
      </Link>
      {children}
    </div>
  );
}
