import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import Link from "next/link";

export function useCount({ auth, setIsLoading }) {
  const [count, setCount] = useState(0);

  const getCount = async (auth) => {
    try {
      setIsLoading(true);
      const req = await fetch(`/api/v1/transactions/carts/count`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + auth,
        },
      });
      const { data, error } = await req.json();
      if (error) {
        throw new Error(error);
      }
      setCount(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth) {
      getCount(auth);
    }
  }, [auth]);

  return { count, setIsLoading };
}

export function useAnchorRef() {
  const anchorRef = useRef(null);
  const [isTopZero, setIsTopZero] = useState(true);

  useEffect(() => {
    let observer = null;
    if (anchorRef.current) {
      observer = new IntersectionObserver((entries) => {
        let bool = !entries[0].isIntersecting;
        if (window.scrollY > 500) {
          bool = false;
        }
        setIsTopZero(bool);
      });
      observer.observe(anchorRef.current);
    }
    return () => {
      if (observer && anchorRef.current) {
        observer.unobserve(anchorRef.current);
      }
    };
  }, [anchorRef.current]);

  return { anchorRef, isTopZero };
}

export function useHover() {
  const [openProgram, setOpenProgram] = useState(false);
  const [openPublikasi, setOpenPublikasi] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  const handleHover = (text, bool) => {
    switch (text) {
      case "Program ▾":
        setOpenProgram(bool);
        break;
      case "Publikasi ▾":
        setOpenPublikasi(bool);
        break;
      case "Tentang Kami ▾":
        setOpenAbout(bool);
        break;
    }
  };

  return { handleHover, openAbout, openProgram, openPublikasi };
}

export function useHandleLogout({
  setIsLoading,
  setAuth,
  setUserData,
  router,
}) {
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const res = await fetch("/api/v1/users/logout", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const resJson = await res.json();

      if (resJson.status !== "success") {
        throw new Error("Gagal");
      }
      localStorage.removeItem("mini-session");
      setAuth("");
      setUserData({});
      localStorage.clear();
      toast.success("Berhasil logout");
      router.push("/");
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleLogout };
}

export function AuthDropdown({ handleLogout, setOpenDropdown }) {
  return (
    <div
      className="absolute top-12 right-0 z-10 mt-0 flex w-auto min-w-[10rem] flex-col justify-end divide-white rounded bg-white p-2 shadow-md"
      onClick={() => setOpenDropdown("")}
    >
      <Link href="/profile">
        <a className="font-sans w-full cursor-pointer p-2 text-left text-sm font-normal hover:bg-gray-100">
          Edit Profile
        </a>
      </Link>
      <Link href="/status">
        <a className="font-sans w-full cursor-pointer p-2 text-left text-sm font-normal hover:bg-gray-100">
          Status Kelas
        </a>
      </Link>
      <Link href="/riwayat-pembelian">
        <a className="font-sans w-full cursor-pointer p-2 text-left text-sm font-normal hover:bg-gray-100">
          Riwayat Pembelian
        </a>
      </Link>
      <button
        onClick={handleLogout}
        className="font-sans w-full cursor-pointer p-2 text-left text-sm font-normal hover:bg-gray-100"
      >
        Keluar
      </button>
    </div>
  );
}

export function MenuDropdown() {
  return (
    <div className="absolute top-12 right-0 z-10 mt-2 flex flex-col justify-center divide-white rounded-md bg-white p-2 shadow-md">
      <Link href={process.env.NEXT_PUBLIC_LMS_URL}>
        <a className="flex flex-col items-center justify-center hover:bg-gray-100">
          <img src="/icon/lms logo.svg" className="h-6 w-6" alt="lms" />
          <p className="font-sans w-full cursor-pointer p-2 text-center text-sm font-medium">
            LMS
          </p>
        </a>
      </Link>
      <Link href={process.env.NEXT_PUBLIC_WECAN_URL}>
        <a className="flex flex-col items-center justify-center hover:bg-gray-100">
          <img src="/icon/wecan logo.svg" className="h-6 w-6" alt="wecan" />
          <p className="font-sans w-full cursor-pointer p-2 text-center text-sm font-medium ">
            WECAN
          </p>
        </a>
      </Link>
    </div>
  );
}

export function useRenderDropdown({ handleLogout, setOpenDropdown }) {
  const renderDropdown = (text) => {
    return (
      <>
        <div
          className="fixed top-0 left-0 h-screen w-screen bg-black/30"
          onClick={() => setOpenDropdown("")}
        ></div>
        {text === "menu" ? (
          <MenuDropdown />
        ) : text === "auth" ? (
          <AuthDropdown
            handleLogout={handleLogout}
            setOpenDropdown={setOpenDropdown}
          />
        ) : null}
      </>
    );
  };

  return { renderDropdown };
}
