export const perusahaan = [
  {
    name: "Tentang Kami",
    href: "/tentang-kami/wardaya-college",
  },
  {
    name: "Prestasi",
    href: "/tentang-kami/prestasi",
  },
  {
    name: "Kontak",
    href: "/tentang-kami/kontak",
  },
  {
    name: "Kebijakan Privasi",
    href: "/tentang-kami/kebijakan-privasi",
  },
  {
    name: "Karir",
    href: "/karir",
  },
  // {
  //   name: "Partnership",
  //   href: "/partnership",
  // },
];

export const kontak = [
  {
    name: "021-21197608",
    image: "/images/footer-telepon.svg",
    href: "#",
  },
  {
    name: "085819336010",
    image: "/images/whatsapp.svg",
    href: "#",
  },
  {
    name: "sekretariat@wardayacollege.com",
    image: "/images/footer-email.svg",
    href: "#",
  },
];

export const sosmed = [
  {
    name: "Instagram",
    image: "/icon/instagram_logo.svg",
    href: "https://www.instagram.com/wardayacollegeofficial/",
  },
  {
    name: "Facebook",
    image: "/icon/facebook_logo.svg",
    href: "https://www.facebook.com/wardayacollege/",
  },
  {
    name: "Youtube",
    image: "/icon/youtube_logo.svg",
    href: "https://www.youtube.com/c/wardayacollege",
  },
  {
    name: "Tiktok",
    image: "/icon/tiktok_logo.svg",
    href: "https://www.tiktok.com/@wardayacollege",
  },
];
