import { useState, useEffect } from "react";
import { useQuery } from "urql";
import { gql } from "@urql/core";
import { client } from "../../graphql/client";

const query = gql`
  query MyQuery(
    $limit: Int
    $offset: Int
    $order: [cms_articles_order_by!]
    $title: String
  ) {
    cms_articles(
      where: { title: { _ilike: $title } }
      limit: $limit
      offset: $offset
      order_by: $order
    ) {
      id
      title
      description
      created_at
      picture
      url
    }
    cms_articles_aggregate(where: { title: { _ilike: $title } }) {
      aggregate {
        count
      }
    }
  }
`;

export const getArtikelServer = async ({
  title,
  limit = 10,
  page = 1,
  order = "desc",
}) => {
  const newTitle = title?.length > 0 ? "%" + title + "%" : "%%";
  const { data, fetching, error } = await client
    .query(query, {
      title: newTitle,
      limit: limit,
      offset: limit * (page - 1),
      order: { created_at: order },
    })
    .toPromise();
  return { data, fetching, error };
};

export default function useArtikel(limit, page) {
  const [selected, setSelected] = useState("Artikel Terbaru");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState({
    order: "created_at",
    isAsc: false,
  });

  const [{ data: tempData, fetching }] = useQuery({
    query: query,
    variables: {
      title: search.length > 0 ? "%" + search + "%" : "%%",
      limit: limit,
      offset: limit * (page - 1),
      order: {
        [orderBy.order]: orderBy.isAsc ? "asc" : "desc",
      },
    },
  });

  const handleOrder = (e) => {
    if (e.value === 0) {
      setSelected(e.text);
      setOrderBy((prev) => ({ ...prev, isAsc: false }));
      return selected, orderBy;
    }
    setSelected(e.text);
    setOrderBy((prev) => ({ ...prev, isAsc: true }));
  };

  useEffect(() => {
    if (tempData) {
      const temps = [];
      for (const item of tempData.cms_articles) {
        temps.push({
          id: item.id,
          title: item.title,
          description: item.description,
          created_at: item.created_at,
          picture: item.picture,
          url: item.url,
        });
      }
      setData(temps);
      setTotal(tempData.cms_articles_aggregate.aggregate.count);
    }
  }, [tempData]);

  return {
    data,
    fetching,
    selected,
    setSelected,
    total,
    setTotal,
    search,
    setSearch,
    handleOrder,
  };
}
