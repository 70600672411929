import { useDataProgram } from "../service/beranda/useDataProgram";
import { useHandleHover } from "../service/useDropdown";
import useBerita from "../service/publikasi/useBerita";
import useArtikel from "../service/publikasi/useArtikel";
import usePromosi from "../service/publikasi/usePromosi";
import DropdownPublikasi from "./dropdown/DropdownPublikasi";
import DropdownProgram from "./dropdown/DropdownProgram";
import DropdownTentang from "./dropdown/DropdownTentang";

export default function Dropdown({ object, getHover, open }) {
  const { program, fetching } = useDataProgram();
  const { handleHover } = useHandleHover({ object, getHover });
  const { data: berita } = useBerita(3);
  const { data: artikel } = useArtikel(3);
  const { data: promosi } = usePromosi(3);

  return (
    <>
      {object.includes("Program") ? (
        <DropdownProgram
          object={object}
          open={open}
          program={program}
          handleHover={handleHover}
        />
      ) : object.includes("Publikasi") ? (
        <DropdownPublikasi
          open={open}
          handleHover={handleHover}
          object={object}
          dataArtikel={artikel}
          dataBerita={berita}
          dataPromosi={promosi}
        />
      ) : (
        <DropdownTentang
          open={open}
          handleHover={handleHover}
          object={object}
        />
      )}
      <style jsx>
        {`
          .dropdown {
            background: #ffffff;
            left: 0;
            mix-blend-mode: normal;
            box-sizing: border-box;
            max-height: ${!open ? 0 : "600px"};
            overflow-y: hidden;
            transition: max-height 0.3s;
            /* Cardd */
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
          }
          p.hide-text {
            display: -webkit-box;
            max-width: 95%;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        `}
      </style>
    </>
  );
}
