import { useDataProgram } from "../service/beranda/useDataProgram";
import { perusahaan, kontak, sosmed } from "../utils/footerUtils";
import Link from "next/link";
import Image from "next/image";

export default function Footer() {
  const { program } = useDataProgram();

  return (
    <footer className="flex w-full flex-col items-center justify-center bg-[#142D59] px-4 pt-[90px] pb-4 text-center font-nunito text-white md:px-8 lg:px-20 xl:px-24 2xl:px-32">
      <div className="mx-auto grid w-full max-w-[1260px] grid-cols-1 items-center justify-center gap-1.5 space-y-5 space-x-0 md:grid-cols-5 md:items-start md:justify-between md:space-y-0">
        <div className="mb-5 flex w-full flex-col items-center space-y-3 md:items-start">
          <a href="/" className="relative items-center">
            <Image
              src="/images/logo.png"
              alt="logo"
              height={50}
              width={120}
              objectFit="contain"
              priority={true}
            />
          </a>
          <div className="text-sm font-bold sm:w-3/4 md:text-left">
            <p className="font-nunito text-sm font-bold leading-[21px]">
              Taman Ratu Indah, Blok. F No. 7, RT.10/RW.13, Duri Kepa, Kec. Kb.
              Jeruk, Daerah Khusus Ibukota Jakarta 11510
            </p>
          </div>
        </div>
        <div className="w-full space-y-5 md:text-left">
          <p className="text-xl font-bold">Program</p>
          <ul className="flex flex-col gap-2">
            {program.map((item, i) => (
              <li key={i} className="cursor-pointer hover:opacity-70">
                <Link href={item.href}>
                  <a className="font-nunito text-sm font-bold capitalize">
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full items-start justify-start space-y-5 md:text-left">
          <p className="text-xl font-bold">Perusahaan</p>
          <ul className="space-y-[10px]">
            {perusahaan.map((item, i) => (
              <li key={i} className="cursor-pointer hover:opacity-70">
                <Link href={item.href}>
                  <a className="font-nunito text-sm font-bold">{item.name}</a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className=" w-full items-start justify-start space-y-5 md:text-left">
          <p className="text-xl font-bold">Sosial Media</p>
          <ul className="space-y-[10px]">
            {sosmed.map((item, i) => (
              <li key={i} className="cursor-pointer hover:opacity-70">
                <Link href={item.href}>
                  <a
                    target="_blank"
                    className="flex items-center justify-center md:justify-start"
                  >
                    <Image
                      src={item.image}
                      alt={item.name}
                      height={24}
                      width={24}
                      priority={true}
                    />
                    <p className="ml-2 text-sm font-bold">{item.name}</p>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className=" w-full items-start justify-start space-y-5 md:text-left">
          <p className="text-xl font-bold">Kontak</p>
          <ul className="space-y-[10px]">
            {kontak.map((item, i) => (
              <li key={i}>
                <div className="flex items-center justify-center md:justify-start">
                  <Image
                    src={item.image}
                    alt={item.name}
                    height={24}
                    width={24}
                    priority={true}
                  />
                  <p className="ml-2 text-sm font-bold">{item.name}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <hr className="mt-16 h-[2px] w-full max-w-[1260px] rounded-full bg-white" />
      <p className="mt-8 text-center text-sm font-bold text-white">
        Copyright 2024 &copy; PT Wardaya Inovasi Pendidikan All rights reserve.
      </p>
    </footer>
  );
}
