export const listlink = [
  {
    text: "Program",
    href: "/program",
  },
  {
    text: "Acara",
    href: "/acara",
    items: [],
  },
  {
    text: "Publikasi",
    href: "#",
    items: [
      {
        name: "Berita",
        href: "/publikasi/berita",
      },
      {
        name: "Artikel",
        href: "/publikasi/artikel",
      },
    ],
  },
  {
    text: "Testimoni",
    href: "/testimoni",
    items: [],
  },
  {
    text: "Tentang Kami",
    href: "#",
    items: [
      {
        name: "Wardaya College",
        href: "/tentang-kami/wardaya-college",
      },
      {
        name: "Prestasi",
        href: "/tentang-kami/prestasi",
      },
      {
        name: "Contact",
        href: "/tentang-kami/kontak",
      },
      {
        name: "Karir",
        href: "/karir",
      },
      // {
      //   name: "Partnership",
      //   href: "/partnership",
      // },
    ],
  },
];
