import "../styles/globals.css";
import { useEffect, useMemo, useState, useCallback, Suspense } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Toaster from "../components/Toaster";
import Whatsapp from "../components/Whatsapp";
import { expireTime, changeExpireTime } from "../utils/expireTime";
import jwtDecode from "jwt-decode";
import {
  Provider,
  Client,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from "urql";
import { AppProvider } from "../context/appContext";
import Loading from "../components/Loading";

function MyApp({ Component, pageProps }) {
  const { asPath } = useRouter();
  const [auth, setAuth] = useState("");
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const urqlClient = useMemo(() => {
    const client = new Client({
      url: process.env.NEXT_PUBLIC_HASURA_URL,
      fetchOptions: () => {
        return {
          headers: {
            "x-hasura-admin-secret": process.env.NEXT_PUBLIC_SECRET_KEY,
          },
        };
      },
      exchanges: [dedupExchange, fetchExchange, cacheExchange],
      requestPolicy: "cache-and-network",
    });
    return client;
  }, []);

  const isAuthPage = useMemo(() => {
    return asPath.includes("auth") || asPath.includes("reset-password");
  }, [asPath]);

  const getTokenUsingRefresh = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await fetch("/api/v1/tokens/refresh", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const resJson = await res.json();
      if (resJson.status !== "success") {
        throw new Error("Silahkan login kembali");
      }
      setAuth(resJson.data.access_token);
      localStorage.setItem("token", resJson.data.access_token);
      changeExpireTime(24 * 60 * 60);
    } catch (err) {
      if (err.message === "Silahkan login kembali") {
        return;
      }
      console.error({ err });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 5);
    }
  }, []);

  const silentRefreshToken = useCallback(async () => {
    try {
      const res = await fetch("/api/v1/tokens/refresh", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const resJson = await res.json();
      if (resJson.status !== "success") {
        throw new Error("Silahkan login kembali");
      }
      setAuth(resJson.data.access_token);
      localStorage.setItem("token", resJson.data.access_token);
    } catch (err) {
      if (err.message === "Silahkan login kembali") {
        return;
      }
      console.error({ err });
    }
  }, []);

  useEffect(() => {
    if (auth.length) {
      setTimeout(() => {
        silentRefreshToken();
      }, expireTime * 1000 - 1000);
    }
  }, [auth.length, expireTime]);

  useEffect(() => {
    getTokenUsingRefresh();
  }, []);

  useEffect(() => {
    let email = localStorage.getItem("user-data");
    if (email) {
      let parse = JSON.parse(email);
      setUserData(parse);
    }

    return () => setUserData({});
  }, []);

  useEffect(() => {
    if (!auth.length) {
      setIsLogin(false);
    }

    return () => setIsLogin(true);
  }, [auth.length]);

  const canonicalUrl = (
    `https://wardayaonline.com` + (asPath === "/" ? "" : asPath)
  ).split("?")[0];

  return (
    <div className="flex  flex-col overflow-clip">
      <Head>
        <title>Wardaya Online by Wardaya College</title>
        {/* <meta
          name="description"
          content="Wardaya Online adalah situs resmi untuk mendapatkan informasi terbaru seputar Wardaya College, mengikuti kelas online Wardaya, dan informasi kelas yang ditawarkan."
        /> */}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta charSet="UTF-8" />
        <meta name="theme-color" content="white" />
        <link rel="icon" href="/images/logo1.png" />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      {!isLoading ? (
        <Provider value={urqlClient}>
          <AppProvider
            value={{ auth, setAuth, setUserData, userData, isLogin }}
          >
            <div className="relative flex w-full flex-col items-center">
              {isAuthPage ? null : <Navbar />}
              <Component {...pageProps} />
              {asPath.includes("carts") ||
              asPath.includes("checkout") ? null : (
                <Whatsapp />
              )}
              {isAuthPage ||
              asPath.includes("carts") ||
              asPath.includes("checkout") ? null : (
                <Footer />
              )}
            </div>
            <Toaster />
          </AppProvider>
        </Provider>
      ) : (
        <div className="fixed top-0 left-0 z-[150] flex h-screen w-screen items-center justify-center bg-black bg-opacity-10">
          <Loading fillcolor={"#EA374C"} />
        </div>
      )}
    </div>
  );
}

export default MyApp;
