import { useState } from "react";
import { listlink } from "../utils/navMobileUtils";
import { useDataProgram } from "../service/beranda/useDataProgram";
import Link from "next/link";
import DropdownMobile from "./DropdownMobile";

export default function NavMobile({ open, getClose }) {
  const [select, setSelect] = useState("");
  const { program } = useDataProgram();

  const handleClick = (item) => {
    if (item !== "Program" && item !== "Publikasi" && item !== "Tentang Kami") {
      getClose(false);
    } else {
      setSelect((prev) => {
        let final = "";
        if (prev !== item) {
          final = item;
        }
        return final;
      });
    }
  };

  const dropIcon = (item) => {
    if (item !== "Acara" && item !== "Testimoni") {
      return (
        <img
          src={`/images/dropdown.svg`}
          alt="dropdown"
          className={`h-3 w-3`}
        />
      );
    }
    return null;
  };

  return (
    <>
      {open ? (
        <nav
          className={`rootnav fixed z-40 flex w-full flex-col justify-start bg-[#142D59] px-4 lg:hidden`}
        >
          <div className="absolute top-7 left-2 h-6 w-6 items-center justify-center lg:hidden">
            <img
              className={`menu-bar h-full w-full cursor-pointer ${
                open ? "rotate-180 transform duration-100" : "duration-100"
              }`}
              onClick={() => getClose(!open)}
              src={open ? "/images/cross.svg" : "/images/menubar.svg"}
              alt="menu"
            />
          </div>

          <div className="flex w-full flex-col gap-5 pt-20">
            {listlink.map((item, i) => (
              <div className="" key={i}>
                <div
                  onClick={() => handleClick(item.text)}
                  className="flex items-center justify-between border-b-2 border-white p-2"
                >
                  <Link href={item.href}>
                    <p className="text-center font-nunito text-sm font-medium text-white">
                      {item.text}
                    </p>
                  </Link>
                  {dropIcon(item.text)}
                </div>
                {item.text === "Program" ||
                item.text === "Publikasi" ||
                item.text === "Tentang Kami" ? (
                  <DropdownMobile
                    data={item.text === "Program" ? program : item.items}
                    text={item.text}
                    select={select}
                    getClose={getClose}
                  />
                ) : null}
              </div>
            ))}
          </div>
        </nav>
      ) : null}

      <style jsx>{`
        .rootnav {
          height: ${open ? "102vh" : "0vh"};
          overflow-y: hidden;
          transition: height 0.5s;
        }
      `}</style>
    </>
  );
}
