import { useCallback } from "react";
import Image from "next/image";
import Link from "next/link";

export function useHandleHover({ object, getHover }) {
  const handleHover = useCallback((object, bool) => {
    getHover(object, bool);
  }, []);

  return { handleHover };
}

export function ProgramComp({ name, image, description }) {
  return (
    <>
      <div className="flex w-2/3 flex-col space-y-2 font-nunito font-bold">
        <p className="text-sm capitalize text-[#142D59]">{name}</p>
        <p className="text-xs text-[#8F8F8F]">{description}</p>
      </div>
      <div className="items-center justify-center">
        <Image src={image} alt={name} height={80} width={80} priority={true} />
      </div>
    </>
  );
}

export function PublikasiComp({ href, text, publikasi, setPublikasi }) {
  return (
    <li>
      <Link href={href}>
        <p
          onMouseEnter={() => setPublikasi(text)}
          className={`w-3/4 cursor-pointer rounded-lg p-2 hover:bg-gray-200 ${
            publikasi === text ? "bg-gray-200" : ""
          }`}
        >
          {text}
        </p>
      </Link>
    </li>
  );
}
