export const listPublikasi = [
  {
    text: "Berita",
    href: "/publikasi/berita",
  },
  {
    text: "Artikel",
    href: "/publikasi/artikel",
  },
  {
    text: "E-Brocure",
    href: "/publikasi/e-brocure",
  },
];

export const listTentangKami = [
  {
    text: "Wardaya College",
    href: "/tentang-kami/wardaya-college",
  },
  {
    text: "Prestasi",
    href: "/tentang-kami/prestasi",
  },
  {
    text: "Kontak",
    href: "/tentang-kami/kontak",
  },
  {
    text: "Karir",
    href: "/karir",
  },
  // {
  //   text: "Partnership",
  //   href: "/partnership",
  // },
];
