import React from "react";
import Link from "next/link";

export default function DropdownMobile({ data, select, text, getClose }) {
  return (
    <ul>
      {data.length && select === text
        ? data.map((item, i) => (
            <li key={i}>
              <Link href={item.href}>
                <p
                  onClick={() => getClose(false)}
                  className="cursor-pointer py-1 pl-4 text-left font-nunito text-sm font-medium capitalize text-white hover:opacity-90"
                >
                  {item.name}
                </p>
              </Link>
            </li>
          ))
        : null}
    </ul>
  );
}
