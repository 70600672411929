import { useState, useContext } from "react";
import { useRouter } from "next/router";
import { listLink, navbar1 } from "../utils/navbarUtils";
import ActiveLink from "./ActiveLink";
import NavMobile from "./NavMobile";
import Dropdown from "./Dropdown";
import Link from "next/link";
import Image from "next/image";
import AppContext from "../context/appContext";
import Loading from "./Loading";
import Login from "./Login";
import Auth from "./Auth";
import {
  useAnchorRef,
  useCount,
  useHandleLogout,
  useHover,
  useRenderDropdown,
} from "../service/useNavbar";

export default function Navbar() {
  const { auth, userData, setAuth, setUserData } = useContext(AppContext);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { count } = useCount({ auth, setIsLoading });
  const { anchorRef, isTopZero } = useAnchorRef();
  const { handleHover, openAbout, openProgram, openPublikasi } = useHover();
  const { handleLogout } = useHandleLogout({
    setIsLoading,
    setAuth,
    setUserData,
    router,
  });
  const { renderDropdown } = useRenderDropdown({
    handleLogout,
    setOpenDropdown,
  });

  const handleOpen = (open) => {
    if (open === "Program ▾") {
      return openProgram;
    } else if (open === "Publikasi ▾") {
      return openPublikasi;
    } else if (open === "Tentang Kami ▾") {
      return openAbout;
    }
  };

  return (
    <>
      <NavMobile
        active={router.asPath}
        open={open}
        getClose={setOpen}
        isTop={isTopZero}
      />
      <nav
        className={`fixed left-0 z-30 w-full bg-[#142D59] py-3 px-2 shadow-[0_0_4px_0_rgba(0,0,0,0.25)] shadow-[#000000] lg:px-12
        xl:px-24 2xl:px-32`}
      >
        <div className="mx-auto grid w-full max-w-[1260px] grid-cols-12 items-center gap-3">
          <div className="col-span-1 block h-6 w-6 items-center justify-center lg:hidden">
            <img
              className={`menu-bar h-full w-full cursor-pointer ${
                open ? "rotate-180 transform duration-100" : "duration-100"
              }`}
              onClick={() => setOpen(!open)}
              src={open ? "/images/cross.svg" : "/images/menubar.svg"}
              alt="menu"
            />
          </div>
          <div className="col-span-5 cursor-pointer lg:col-span-2">
            <Link href="/">
              <a className="relative items-center">
                <Image
                  src="/images/logo.png"
                  alt="logo"
                  height={50}
                  width={120}
                  objectFit="contain"
                  priority={true}
                />
              </a>
            </Link>
          </div>
          <div className="col-span-6 hidden lg:block">
            <ul className="flex items-center justify-start">
              {listLink.map((item, i) => (
                <li key={i}>
                  <ActiveLink
                    text={item.text}
                    href={item.href}
                    active={router.pathname}
                    getHover={handleHover}
                  >
                    {item.text !== "Acara" && item.text !== "Testimoni" ? (
                      <Dropdown
                        getHover={handleHover}
                        open={handleOpen(item.text)}
                        object={item.text}
                      />
                    ) : null}
                  </ActiveLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-6 flex flex-col gap-0 lg:col-span-4 lg:gap-3.5">
            <ul
              className={`hidden items-center justify-end gap-3.5 ${
                isTopZero ? "lg:flex" : "lg:hidden"
              }`}
            >
              {navbar1.map((item, i) => (
                <li
                  key={i}
                  className={`flex cursor-pointer items-center justify-center space-x-1 rounded-md bg-[#0A2556] px-2 py-1 text-white hover:bg-white hover:text-[#142D59]`}
                >
                  <Link href={item.href}>
                    <a target="_blank" className="flex items-center gap-2">
                      <img
                        src={item.img}
                        alt={item.text}
                        className="h-[1.1875rem] w-3.5"
                      />
                      <p className="font-nunito">{item.text}</p>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <div className={`relative flex items-center justify-end`}>
              {auth.length && userData?.email?.length ? (
                <Auth
                  count={count}
                  openDropdown={openDropdown}
                  setOpenDropdown={setOpenDropdown}
                  renderDropdown={renderDropdown}
                  userData={userData.email}
                />
              ) : (
                <Login />
              )}
            </div>
          </div>
        </div>
      </nav>
      <div
        ref={anchorRef}
        className="absolute left-0 top-[101vh] h-0.5 w-0.5 bg-black"
      ></div>

      {isLoading ? (
        <div className="fixed top-0 left-0 z-[150] flex h-screen w-screen items-center justify-center bg-black bg-opacity-10">
          <Loading fillcolor={"#EA374C"} />
        </div>
      ) : null}
    </>
  );
}
