import Link from "next/link";

export default function Auth({
  count,
  setOpenDropdown,
  userData,
  renderDropdown,
  openDropdown,
}) {
  return (
    <div className="flex w-full items-center justify-end gap-2">
      <Link href="/keranjang">
        <div className="relative">
          <img
            src="/icon/white-cart.svg"
            alt="cart"
            title="cart"
            className="aspect-square h-7 w-7 cursor-pointer"
          />
          {count !== 0 && (
            <div className="absolute -top-2 left-3 flex h-5 w-5 items-center justify-center rounded-full bg-red-600">
              <p className="text-xs text-white">{count}</p>
            </div>
          )}
        </div>
      </Link>
      <div className="relative">
        <img
          src="/icon/dots-menu.svg"
          className="aspect-square h-7 w-7 cursor-pointer"
          alt="menu dot"
          title="menu dot"
          onClick={() => setOpenDropdown("menu")}
        />
        {openDropdown === "menu" ? renderDropdown("menu") : null}
      </div>
      <div className="relative">
        <div
          onClick={() => setOpenDropdown("auth")}
          className="hidden w-full max-w-[200px] cursor-pointer items-center space-x-3 rounded bg-white p-2 shadow-md hover:bg-gray-100 lg:block"
        >
          <p className="font-sans truncate font-medium">{userData} ▼</p>
        </div>
        <img
          src="/icon/nav-profile.svg"
          alt="profile"
          title="profile"
          className="block aspect-square h-8 w-8 cursor-pointer lg:hidden"
          onClick={() => setOpenDropdown("auth")}
        />
        {openDropdown === "auth" ? renderDropdown("auth") : null}
      </div>
    </div>
  );
}
