import { useState, useEffect } from "react";
import { useQuery } from "urql";
import { gql } from "@urql/core";

const query = gql`
  query MyQuery($limit: Int, $offset: Int, $order: [cms_promotions_order_by!]) {
    cms_promotions(
      limit: $limit
      offset: $offset
      order_by: $order
      where: { deleted_at: { _is_null: true } }
    ) {
      link
      id
      url
      program
      title
      description
      created_at
    }
  }
`;

export default function usePromosi(limit) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState({
    order: "created_at",
    isAsc: true,
  });

  const [{ data: tempData, fetching, error }] = useQuery({
    query: query,
    variables: {
      limit: limit,
      offset: limit * (page - 1),
      order: {
        [orderBy.order]: orderBy.isAsc ? "asc" : "desc",
      },
    },
  });

  useEffect(() => {
    if (tempData) {
      const temps = [];
      for (const item of tempData.cms_promotions) {
        temps.push({
          link: item.link,
          id: item.id,
          url: item.url,
          program: item.program,
          title: item.title,
          description: item.description,
          created_at: item.created_at,
        });
      }
      setData(temps);
    }
  }, [tempData]);

  return { data, fetching, error };
}
