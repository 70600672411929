import Link from "next/link";
import { listTentangKami } from "../../utils/dropdownUtils";

export default function DropdownTentang({ open, handleHover, object }) {
  return (
    <div
      className={`absolute top-10 flex w-36 flex-col items-center overflow-hidden rounded-xl bg-white px-10 shadow-lg duration-300 ${
        open ? "max-h-[40rem] p-4" : "h-0"
      }`}
      onMouseEnter={() => {
        handleHover(object, true);
      }}
      onMouseLeave={() => {
        handleHover(object, false);
      }}
    >
      {listTentangKami.map((item, i) => (
        <Link key={i} href={item.href}>
          <a className="w-36 cursor-pointer border-b p-2 text-left font-nunito text-sm font-medium hover:bg-blue-100 hover:opacity-80">
            {item.text}
          </a>
        </Link>
      ))}
    </div>
  );
}
