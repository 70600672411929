import Image from "next/image";
import Link from "next/link";

export default function DropdownProgram({
  object,
  open,
  program,
  handleHover,
}) {
  return (
    <ul
      className={`absolute top-10 grid w-[79rem] grid-cols-4 gap-x-[1.25rem] gap-y-[1.9rem] overflow-hidden rounded-xl bg-white shadow-lg duration-300 lg:-left-48 ${
        open ? "max-h-[40rem] p-4" : "h-0"
      }`}
      onMouseEnter={() => handleHover(object, true)}
      onMouseLeave={() => handleHover(object, false)}
    >
      {program.map((item, i) => (
        <li key={i} onClick={() => handleHover(object, false)}>
          <Link href={`/program/${item.name}`}>
            <a className="col-span-1 flex h-40 cursor-pointer space-x-3 rounded-xl border p-4 hover:bg-blue-100 hover:opacity-80">
              <div className="flex w-2/3 flex-col space-y-2 font-nunito font-bold">
                <p className="text-sm capitalize text-[#142D59]">{item.name}</p>
                <p
                  className="program-text text-xs text-[#8F8F8F]"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                ></p>
              </div>
              <div className="relative w-1/3 items-center justify-center">
                <Image
                  src={item.image}
                  alt={item.name}
                  height={80}
                  width={80}
                  objectFit="contain"
                  priority={true}
                />
              </div>
            </a>
          </Link>
        </li>
      ))}
      <style jsx>
        {`
          p.program-text {
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        `}
      </style>
    </ul>
  );
}
