export const listLink = [
  {
    text: "Program ▾",
    href: "/program",
  },
  {
    text: "Acara",
    href: "/acara",
  },
  {
    text: "Publikasi ▾",
    href: "",
  },
  {
    text: "Testimoni",
    href: "/testimoni",
  },
  {
    text: "Tentang Kami ▾",
    href: "",
  },
];

export const navbar1 = [
  // {
  //   text: "Wardaya Online",
  //   href: "https://wardayaonline.com",
  //   img: "/images/wardaya-logo.webp",
  //   image: "/images/wardaya-logo.png",
  //   navbar: "/images/logo.webp",
  // },
  {
    text: "WE CAN",
    href: "https://wecan.wardayaonline.com",
    img: "/images/wecan-logo.webp",
    image: "/images/wecan-logo.png",
    navbar: "/images/wecan2.webp",
  },
  {
    text: "IRC",
    href: "https://irc.wardayaonline.com",
    img: "/images/irc-logo.webp",
    image: "/images/irc-logo.png",
    navbar: "/images/irc-putih.webp",
  },
];
