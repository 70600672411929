import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { listPublikasi } from "../../utils/dropdownUtils";

export default function DropdownPublikasi({
  open,
  object,
  dataArtikel,
  dataBerita,
  dataPromosi,
  handleHover,
}) {
  const [publikasi, setPublikasi] = useState("Berita");
  const berita = "/publikasi/berita";
  const artikel = "/publikasi/artikel";
  const promosi = "/publikasi/e-brocure";
  const isPromosi = publikasi === "E-Brocure";

  const CompPublikasi = ({ publikasi, berita, artikel, promosi, item }) => {
    const hrefLink = (publikasi) => {
      if (publikasi) {
        if (publikasi === "Artikel") {
          return artikel;
        }
        if (publikasi === "E-Brocure") {
          return promosi;
        }
        return berita;
      }
    };

    return (
      <Link href={hrefLink(publikasi) + `/${item.url}`}>
        <a className="col-span-1 flex w-full items-center gap-2 p-2 hover:bg-blue-100 hover:opacity-80">
          {isPromosi ? null : (
            <div className="relative flex h-24 w-1/6 items-center justify-center">
              <Image
                src={item.picture ? item.picture : "/images/logo.png"}
                alt={item.url}
                title={item.title}
                height={96}
                width={96}
                objectFit="contain"
                priority={true}
              />
            </div>
          )}
          <p className="hide-text w-5/6 font-nunito text-sm capitalize">
            {item.title}
          </p>
        </a>
      </Link>
    );
  };

  const dataRender = (name) => {
    if (name === "Artikel") {
      return dataArtikel;
    }
    if (name === "E-Brocure") {
      return dataPromosi;
    }
    return dataBerita;
  };

  return (
    <>
      <div
        className={`absolute top-10 -left-[20rem] flex w-[70rem] items-start overflow-hidden rounded-xl bg-white font-nunito text-[#142D59] shadow-lg duration-300 ${
          open ? "max-h-[40rem] p-4" : "h-0"
        }`}
        onMouseEnter={() => {
          handleHover(object, true);
        }}
        onMouseLeave={() => {
          handleHover(object, false);
        }}
      >
        <ul className="flex h-full w-1/5 flex-col space-y-4 text-sm font-bold">
          {listPublikasi.map((item, i) => (
            <li key={i}>
              <Link href={item.href}>
                <a>
                  <p
                    onMouseEnter={() => setPublikasi(item.text)}
                    className={`w-4/5 cursor-pointer rounded-lg p-2 hover:bg-gray-200 ${
                      publikasi === item.text ? "bg-gray-200" : ""
                    }`}
                  >
                    {item.text}
                  </p>
                </a>
              </Link>
            </li>
          ))}
        </ul>
        <div className="border-2-2 absolute left-[12.5rem] h-[21rem] border border-dashed border-[#D9D9D9]"></div>
        <div className="w-4/5 grid-cols-3 gap-2">
          {dataRender(publikasi).map((item, i) => (
            <CompPublikasi
              key={i}
              item={item}
              publikasi={publikasi}
              berita={berita}
              artikel={artikel}
              promosi={promosi}
            />
          ))}
        </div>
      </div>

      <style>
        {`
          p.hide-text {
            display: -webkit-box;
            max-width: 85%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          `}
      </style>
    </>
  );
}
